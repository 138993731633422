import React from "react";

function Bio(props) {
    return (
        <div>
            <p>{props.description}</p>
        </div>
    )
}

export default Bio

