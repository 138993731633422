import React from "react"
import { Link } from "gatsby"

import { rhythm, scale } from "../utils/typography"

import Bio from "../components/bio"

import InflectionLogo from "../../static/assets/inflection_logo.svg"

import style from "./layout.module.css"

const Layout = ({ location, title, children, description }) => {
  const rootPath = `${__PATH_PREFIX__}/`

  let header

  if (location.pathname === rootPath) {
    header = (
      <div className={style.container}>
        <InflectionLogo className={style.logo}/>
        <div className={style.upperBox}>
          <h1
            style={{
              marginTop: 0,
              marginBottom: 0
            }}
          >
            <Link
              to={`/`}
            >
              {title}
            </Link>
          </h1>
          <Bio description={description} />
        </div>
      </div>

    )
  } else {
    header = (
      <h3
        style={{
          fontFamily: `Montserrat, sans-serif`,
          marginTop: 0,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h3>
    )
  }
  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(24),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }}
    >
      <header>{header}</header>
      <main>{children}</main>
      {/* <footer>
        © {new Date().getFullYear()} Inflection.blog, Built with
        {` `}
        <a href="https://www.gatsbyjs.org">Gatsby.js</a>
      </footer> */}
    </div>
  )
}

export default Layout
