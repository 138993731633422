import Typography from "typography"

//import Wordpress2016 from "typography-theme-wordpress-2016"

import StAnnes from "typography-theme-st-annes";

StAnnes["baseFontSize"] = "20"
StAnnes.overrideThemeStyles = () => {
  return {
    a: {
        color: "#2f64e0"
    },
    h3: {
     color: "#2f64e0",
    },
    blockquote: {
        "border-color": "#9d9ea1"
    }
  }
}

delete StAnnes.googleFonts

const typography = new Typography(StAnnes)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
